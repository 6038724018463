document.addEventListener("turbolinks:load", function () {

    var notification = document.querySelector('.notice');

    if (notification) {
        window.setTimeout(function () {
            notification.style.visibility = "hidden";
            notification.style.opacity = "0";
            notification.style.transition = "visibility 0s .5s, opacity .5s linear";
        }, 2500);
        window.setTimeout(function () {
            notification.style.display = "none";
        }, 3000);
    }

    var alert = document.querySelector('.alert');

    if (alert) {
        window.setTimeout(function () {
            alert.style.visibility = "hidden";
            alert.style.opacity = "0";
            alert.style.transition = "visibility 0s .5s, opacity .5s linear";
        }, 2500);
        window.setTimeout(function () {
            alert.style.display = "none";
        }, 3000);
    }

});